.appBar {
  .imageContainer {
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
      max-width: 200px;
    }
  }

  .languagePicker {
    padding-inline: 24px;
  }

  @media (min-width: 900px) {
    #menu-appbar {
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: center !important;
    }
  }

  @media (max-width: 768px) {
    .languagePicker {
      padding: 12px;
    }
  }
}

#menu-appbar-mobile {
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    background-color: #faae00 !important;
    left: 0 !important;
    max-width: 100% !important;
    width: 100% !important;
    box-shadow: none !important;
    top: 70px !important;
    color: white !important;
  }
}

#menu-appbar-language-selector {
  .languageFlag {
    width: 20px;
    margin-right: 8px;
  }
}





