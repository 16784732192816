.oilMillsPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }

  .titleContainer {
    margin-bottom: 24px;
    color: black;
    height: 20%;
    background-color: #faae00;
    border-radius: 20px;
    padding-inline: 40px;
  }

  .oilMillsContent {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;

    .imageContainer {
      text-align: center;
      margin-block: 24px;
      margin-inline: auto;

      img {
        max-width: 500px;
        width: 100%;
        height: 500px;
        margin-inline: auto;
        object-fit: cover;
      }
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 22px;
      align-items: flex-start;
      flex: 1 0 515px;
    }
  }

  @media (max-width: 768px) {
    .oilMillsContent {
      .contentContainer {
        flex: 1;
        padding-inline: 32px;
      }

      .imageContainer {
        img {
          height: auto;
        }
      }
    }
  }
}