.certificatesPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1, h2 {
    margin-bottom: 0;
  }

  p {
    font-size: 20px;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 16px;
    border: 1px solid #d9d9d9;
    width: 300px;
  }

  section {
    margin-bottom: 48px;
  }

  .titleContainer {
    margin-bottom: 24px;
    color: black;
    height: 20%;
    background-color: #faae00;
    border-radius: 20px;
    padding-inline: 40px;
    text-align: center;
  }

  .certificatesContainer {
    max-width: 400px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    p {
      font-size: 16px;
    }

    .certificatesPageContent {
      padding-inline: 32px;
    }
  }
}