.content {
  flex: 1;
  padding-block: 32px;
  padding-inline: 10%;
  position: relative;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f6f6f6;
}

::-webkit-scrollbar-thumb {
  background: #999999;
}

::-webkit-scrollbar-thumb:hover {
  background: #faae00;
}

@media (max-width: 768px) {
  .content {
    padding-inline: 0;
  }
}