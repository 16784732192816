.productsPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1, h2 {
    margin-bottom: 0;
  }

  .titleContainer {
    margin-bottom: 24px;
    color: black;
    height: 20%;
    background-color: #faae00;
    border-radius: 20px;
    padding-inline: 40px;
    text-align: center;
  }

  .productsPageHeader {
    margin-bottom: 2rem;

    .productsPageDescription {
      font-size: 1.5rem;
    }
  }

  .productsList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;

    .product {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;

      .productImgContainer {
        height: 400px;
      }

      .productImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      .productInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .productName {
          background-color: #faae00;
          color: black;
          padding: 8px 16px;
          text-align: center;
        }

        .productDescription {
          flex: 1;
          padding: 32px;
          color: black;
          background-color: #f0f0f0;
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .productsPageHeader {
      .productsPageDescription {
        padding-inline: 32px;
      }
    }

    .productsList {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
}
