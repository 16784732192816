@import url("react-responsive-carousel/lib/styles/carousel.min.css");

* {
    margin: 0;
    box-sizing: border-box;
    text-transform: none;
}

body, html, #root {
    height: 100vh;
}

#root {
    display: flex;
    flex-direction: column
}

body {
    background: white;
    font-size: 16px;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

p, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1em;
}