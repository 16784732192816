.footer {
  display: grid;
  grid-template-areas: 
  "l m m e"
  "l m m e"
  "a a a a";
  padding-block: 16px;
  padding-inline: 10%;
  background-color: #faae00;
  color: white;
  gap: 32px;

  svg {
    width: 16px;
    height: 16px;
    color: white;
  }

  .footerLogo {
    grid-area: l;
  }

  .footerMenu {
    grid-area: m;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    place-items: center;

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: white;
      font-weight: 500;
      text-transform: uppercase;
    }

    .menuItemElement,
    .allRightsContent {
      font-size: 12px;
    }
  }

  .footerAllRights {
    grid-area: a;
    margin-left: auto;
    margin-right: auto;
  }

  .footerMail {
    grid-area: e;
    margin-block: auto;

    .link {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: white;
      justify-content: center;

      svg {
        margin-right: 8px;
      }
    }
  }

  .allRightsContent {
    font-weight: 300;
    text-align: center;
    margin-top: 20px;
  }

  .logo {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    display: block;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .footerMenu {
      gap: 16px;
    }

    .footerAllRights {
      text-align: center;
    }
  }
}

