.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1, h2 {
    margin-bottom: 0;
  }

  .titleContainer {
    margin-bottom: 24px;
    color: black;
    height: 20%;
    background-color: #faae00;
    border-radius: 20px;
    padding-inline: 40px;
    text-align: center;
  }

  .contactElements {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-top: 16px;
    margin-bottom: 32px;

    .contactInfoItemsList {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .contactInfoItem {
        display: flex;

        svg {
          width: 32px;
          height: 32px;
          margin-right: 8px;
          color: #faae00;
        }

        h2 {
          font-weight: normal;
          margin-bottom: 8px;
        }

        .description {
          color: #7f7f7f;
        }
      }
    }

    .contactFormContainer {
      form {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }

  .locationMapContainer {
    width: 100%;

    .locationMap {
      display: flex;
      flex-direction: column;
    }
  }

  .contactInfoTitle {
    margin-bottom: 16px;
  }

  @media (max-width: 768px) {
    .contactElements {
      display: flex;
      flex-direction: column;
      padding-inline: 32px;
    }
  }
}