.customersPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1, h2 {
    margin-bottom: 0;
  }

  .titleContainer {
    margin-bottom: 24px;
    color: black;
    height: 20%;
    background-color: #faae00;
    border-radius: 20px;
    padding-inline: 40px;
    text-align: center;
  }

  .customersPageHeader {
    margin-bottom: 2rem;

    .customersPageDescription {
      font-size: 1.5rem;
    }
  }

  .customersList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;

    .customer {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;

      .customerImgContainer {
        height: 400px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }

      .customerInfo {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .customerName {
          background-color: #faae00;
          color: black;
          padding: 8px 16px;
          text-align: center;
        }

        .customerDescription {
          flex: 1;
          padding: 16px;
          color: black;
          background-color: #f0f0f0;
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .customersPageHeader {
      padding-inline: 32px;
    }

    .customersList {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
}