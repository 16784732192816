.homePage {
  .homeCarousel {
    margin-bottom: 24px;
    margin-inline: calc(-50vw + 50%);
    max-width: calc(100vw - 5px);
    margin-top: -32px;
  }

  .carousel-slider {
    max-height: 500px;
  }

  .homePageContent {
    .homePageTitle {
      color: #faae00;
      margin-bottom: 24px;
    }

    .homePageDescription {
      margin-bottom: 24px;
    }

    .homePageInfo {
      display: grid;
      grid-gap: 32px;

      .homePageInfoSection {
        display: flex;
        flex-direction: column;

        .sectionIcon {
          color: #faae00;
          margin-bottom: 16px;
          width: 32px;
          height: 32px;
        }

        .sectionTitle {
          color: #faae00;
        }

        .sectionDescription {
          margin-block: 16px;
          flex: 1;
        }
      }
    }

    .homePageLocation {
      display: grid;
      grid-gap: 40px;
      padding-block: 40px;

      .locationInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 1.5rem;
      }

      .locationMap {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  @media (min-width: 768px) {
    .homePageContent {
      .homePageInfo {
        grid-template-columns: repeat(3, 1fr);
      }

      .homePageLocation {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media (max-width: 768px) {
    .homePageContent {
      padding-inline: 32px;

      .homePageInfo {
        text-align: center;
      }

      .locationMap {
        margin-inline: calc(-50vw + 50%);
        max-width: calc(100vw - 5px);
      }
    }
  }
}