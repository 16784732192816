.aboutUsPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }

  p {
    font-size: 20px;
    color: #696969;
    margin: 0;
  }

  .titleContainer {
    margin-bottom: 24px;
    color: black;
    background-color: #faae00;
    border-radius: 20px;
    padding-inline: 40px;
    text-align: center;
  }

  .card {
    display: flex;
    margin-block: 5%;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

    .imageContainer {
      width: auto;
      max-width: 400px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .section {
      margin-block: auto;
      padding: 32px;

      .sectionDescription {
        line-height: 1.5;
      }
    }
  }

  @media (max-width: 768px) {
    .card {
      flex-direction: column;

      .imageContainer {
        max-width: unset;
      }
    }
  }
}