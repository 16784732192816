.processPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }

  p {
    font-size: 22px;
  }

  .titleContainer {
    margin-bottom: 24px;
    color: black;
    height: 20%;
    background-color: #faae00;
    border-radius: 20px;
    padding-inline: 40px;
    text-align: center;
  }

  .processLifecycleImage {
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 600px;
    }
  }

  @media (max-width: 768px) {
    p {
      font-size: 16px;
      padding-inline: 5px;
    }

    .processPageContent {
      padding-inline: 32px;
    }
  }
}